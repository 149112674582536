<template>
  <v-app id="inspire" color="fondo1">



    <v-navigation-drawer v-model="drawer"  v-if="(logeado==true)&& (sesion==true) && ((esAdminstrador==true)||(esUsuarioComun==true))" app class="pt-4" color="fondo1"   permanent expand-on-hover >



    <v-list v-if="(logeado==true)&& (sesion==true) && ((esAdminstrador==true)||(esUsuarioComun==true))"  dense >
          <v-list-item class="my-2" v-show="logeado==true" v-for="item in items" :key="item.text"  :to="{name:item.to}" link>

            <v-list-item-action>
              <v-icon color="iconos1">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="white--text">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

    </v-list>

 
    </v-navigation-drawer>


    <v-app-bar app style="width:100% !important;" color="indigo" v-if="(logeado==true)&& (sesion==true) && (esUsuarioTercerizado==true)">
       <v-app-bar-nav-icon 
                           class="d-flex d-sm-none"
                           ></v-app-bar-nav-icon>
        <v-toolbar-title class="white--text">Acceso Tercerizados</v-toolbar-title>
        <v-spacer></v-spacer>
  
        <v-tooltip bottom class="float-right">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon  v-bind="attrs"  v-on="on" color="white" @click="dialogExit=true"> 
                  <v-icon color="white">logout</v-icon>
                </v-btn>
              </template>
              <span>Salir de aplicacion</span>
          </v-tooltip>
      </v-app-bar>


<v-main style="background-color:#F4F5FA;">
      <v-container style="background-color:#F4F5FA;width:100%;">

        <router-view  style="background-color:#F4F5FA;" />


         <v-dialog
                   v-model="loading.estado"
                    hide-overlay
                     persistent
                     width="300">
                        <v-card
                          color="info"
                          dark
                        >
                          <v-card-text>
                          {{loading.titulo}}
                            <v-progress-linear
                              indeterminate
                              color="white"
                              class="mb-0"
                            ></v-progress-linear>
                          </v-card-text>
                        </v-card>
                </v-dialog>

      </v-container>
      </v-main>

      <v-dialog v-model="dialogExit"  hide-overlay
                     persistent
                     width="250">
                        <v-card>
                          <v-card-title>Salir de aplicacion</v-card-title>
                          <v-card-text>Deseas salir?</v-card-text>
                          <v-card-actions class="justify-space-around">
                            <v-btn @click="dialogExit=false" text>Cancelar</v-btn>
                          <v-btn @click="cerrar()" dark color="indigo">Salir</v-btn></v-card-actions>
                        </v-card>
  </v-dialog>
    
  </v-app>
</template>


<script>

import { mapState,mapMutations } from "vuex";


export default {


  data: () => ({
    //

     dialog: false,

     drawer: null,
      items: [
      { icon: 'home', text: 'Inicio', to:'home'},
      { icon: 'maps', text: 'Mapa', to:'mapa'},
      { icon: 'speed', text: 'Velocidades', to:'excesos'},        
      { icon: 'room', text: 'Paradas', to:'paradas' },             
      { icon: 'timer', text: 'Ralenti' , to:'ralenti'},            
      { icon: 'campaign', text: 'Botones panico', to:'botones' },  
      { icon: 'local_gas_station', text: 'Combustible', to:'combustible' }, 
      { icon: 'home_repair_service', text:'Mantenimientos', to:'mantenimiento'},
      { icon: 'insert_chart_outlined' , text: 'Estadisticas', to:'estadisticas' },
      { icon: 'account_circle' , text: 'Conductores', to:'eventosconductor' },
      { icon: 'settings', text: 'Configuracion', to:'configuracion' }
      ],

        items2: [
       { icon:'commute', text: 'Vehiculos', to:'' },
       { icon:'perm_identity', text: 'Conductores', to:'' },
       { icon:'layers', text: 'Geocercas', to:'' },
      
    ],

      miniVariant: true,
      hover: false,
      dialogExit:false,
      sesion:true
    
  }),
  props: {
      source: String
    },

    watch:{

      hover:function(val){
        if(val){
            this.miniVariant=false;
        }else{
          this.miniVariant=true;
        }

      },

      '$store.state.usuario': function() {
           // console.log('watch cambio');
           console.log(this.$store.state.usuario);
              if(this.$store.state.usuario==null){
                this.sesion=false;
                this.drawer=false;
              }else{
                this.sesion=true;
                 
              }

              
      }

    },

    computed:{
       ...mapState(['loading']),

       logeado(){

        let token_storage=localStorage.getItem('token');

        if(token_storage){
          console.log('se detecto cambio en storage:'+this.$store.state.usuario);
          return true;
        }else{

           console.log('se detecto cambio en storage:'+this.$store.state.usuario+' --->');
          return false;
        }
       
         // return this.$store.state.usuario;
      },

      esAdminstrador(){
          return this.$store.state.usuario && this.$store.state.usuario.rol=='ADMIN';
      }, 
      
      esUsuarioComun(){
          return this.$store.state.usuario && this.$store.state.usuario.rol=='USER';
      },

      esUsuarioTercerizado(){
          return this.$store.state.usuario && this.$store.state.usuario.rol=='TERCERIZADO';
      }

    },
    methods:{
      ...mapMutations(['mostrarLoading','ocultarLoading']),

     salir(){
          this.drawer=false;
          this.sesion=false;
          this.$store.dispatch("salir");
      },

      cerrar(){
              this.dialogExit=false;
              localStorage.removeItem('usuario');
              localStorage.removeItem('token');
              this.sesion=false;
              this.drawer=false;
              this.salir();
            },


    }
    
};
</script>

<style lang="css" scoped>
.map-marker-radius{
	fill: white;
}

.container{
     max-width: 100vw;
     padding:0px;
  }
</style>
