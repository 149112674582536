import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'



import store from '../store';

Vue.use(VueRouter);


const routes = [


  {
    path: '/olvidepassword',
    name: 'olvidepassword',
    component: () => import(/* webpackChunkName: "login" */ '../views/ForgotPassword.vue'),
    meta:{
      libre:true
    }
  },


  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue'),
    meta:{
      libre:true
    }
  },

  {
    path: '/home',
    name: 'home',
    component:  () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
      usuario_tercerizado:false
    }
  },


  {
    path: '/mapa',
    name: 'mapa',
    component:  () => import(/* webpackChunkName: "about" */ '../views/MapaMonitoreo.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
      usuario_tercerizado:false
    }
  },



  {
    path: '/dashpais',
    name: 'dashpais',
    component: () => import('../views/DashPais.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
      usuario_tercerizado:false
    }
  },


  {
    path: '/dashvehiculos',
    name: 'dashvehiculos',
    component:  () => import('../views/DashVehiculos.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
      usuario_tercerizado:false
    }
  },



  {
    path: '/',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
      usuario_tercerizado:true,
    }
  },

  {
    path: '/about',
    name: 'inicio',
    component: () => import('../views/About.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
      usuario_tercerizado:false
    }
  },

  {
    path: '/configuracion',
    name: 'configuracion',
    component: () => import('../views/configuraciones/Configuracion.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
      usuario_tercerizado:false
    }
  },

  {
    path: '/flotas',
    name: 'flotas',
    component: () => import('../views/configuraciones/Flotas.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
      usuario_tercerizado:false
    }
  },

  {
    path: '/mantenimiento',
    name: 'mantenimiento',
    component: () => import('../views/mtto/Mantenimiento.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
      usuario_tercerizado:false
    }
  },

  {
    path: '/combustible',
    name: 'combustible',
    component: () => import('../views/combustibles/Combustible.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
      usuario_tercerizado:false
    }
  },

  {
    path: '/botones',
    name: 'botones',
    component: () => import('../views/boton/Vista.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
      usuario_tercerizado:false
    }
  },

  
  {
    path: '/ralenti',
    name: 'ralenti',
    component: () => import('../views/ralenti/Vista.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
      usuario_tercerizado:false
    }
  },


  {
    path: '/paradas',
    name: 'paradas',
    component: () => import('../views/parada/Vistas.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
      usuario_tercerizado:false
    }
  },


  {
    path: '/excesos',
    name: 'excesos',
    component: () => import('../views/exceso/Vista.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
      usuario_tercerizado:false
    }
  },


  {
    path: '/estadisticas',
    name: 'estadisticas',
    component: () => import('../views/Estadisticas.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
      usuario_tercerizado:false
    }
  },

  {
    path: '/eventosconductor',
    name: 'eventosconductor',
    component: () => import('../views/conductores/EventosConductor.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
      usuario_tercerizado:false
    }
  },

  {
    path: '/jornadas',
    name: 'jornadas',
    component: () => import('../views/conductores/Jornadas.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
      usuario_tercerizado:false
    }
  },

  {
    path: '/hometercerizados',
    name: 'hometercerizados',
    component: () => import('../views/HomeTercerizados.vue'),
    meta:{
      administrador:false,
      usuario_comun:false,
      usuario_tercerizado:true
    }
  }



 



];




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});




router.beforeEach((to, from, next)=>{



  //si alguien recarga la url debemos tomar ese token e ingresarlo al state

const token_storage=localStorage.getItem('token');

if(token_storage!=null){


  if(!store.state.usuario){

    console.log('token encontrado');
    console.log(token_storage);
    store.dispatch("guardarToken", token_storage);
  }

}




  if(to.matched.some(record=>record.meta.libre)){
    next();


    //validamos rutas solo administrador
  }else if( store.state.usuario && store.state.usuario.rol=='ADMIN'){


    if(to.matched.some(record=>record.meta.administrador)){
      next();
    }

    
    //ruta para usuarios comunes
  }else if(store.state.usuario && store.state.usuario.rol=='USER'){

    if(to.matched.some(record=>record.meta.usuario_comun)){
          next();
        }

    //si no tiene ningun permiso salir
  }else if(store.state.usuario && store.state.usuario.rol=='TERCERIZADO'){

    if(to.matched.some(record=>record.meta.usuario_tercerizado)){
          next();
        }

    //si no tiene ningun permiso salir
  }else{
    next({name:'login'});

    
    console.log('entro en ruta libre');

  }

});




export default router
