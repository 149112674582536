import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false


import mdiVue from 'mdi-vue'
import * as mdijs from '@mdi/js'

Vue.use(mdiVue, {
  icons: mdijs
});


import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);


import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx);

import VueScreen from 'vue-screen';

Vue.use(VueScreen);


import Notifications from 'vue-notification';
Vue.use(Notifications);


//import Notifications from '@voerro/vue-notifications'
//Vue.component('notifications', Notifications);




import axios from 'axios';

import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);


import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAjuvlLguzyb37BTCHrVIWJ1RPHN0c00Lw',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
  },
});

// api servidor local
//axios.defaults.baseURL='http://localhost:3000/api/';


// api servidor digital ocean http
//axios.defaults.baseURL='http://137.184.54.247:3000/api/';


// api servidor digital ocean http
axios.defaults.baseURL='https://indicadorestransporteswdeca.com/api/';


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
